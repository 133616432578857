<template>
  <v-row no-gutters>
    <v-col
        v-if="treeLength > treeMin" class="d-inline-block" :style="'max-width:' + treeLength * 10 + 'px'">
      <span class="lShape">|</span>
      <span v-for="line in treeLength" :key="line">_</span>
    </v-col>
    <v-col>
      <div v-if="checkedSource != checkInputSource">
        <primaryTextfield
            :value="tag" :fieldAttrInput="{
            class: 'attribute-text-' + result.valueLine.color,
            disabled: true,
          }" :templateContent="result"></primaryTextfield>
      </div>
      <div v-else>
        <associatedOption
            type="combobox"
            :fieldAttributes="fieldAttributes"
            :value="localValue"
            v-on="$listeners"
            :templateContent="result"></associatedOption>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import primaryTextfield from '@/commonComponents/primaryTextfield.vue';
import associatedOption from '@/commonComponents/associatedOption.vue';
import {arrayFindInArray} from '@/js/helper.js';

export default {
  components: { associatedOption, primaryTextfield },
  props: ["result", "fieldAttributes","value"],
  computed: {
    treeMin() {
      let treeMin = 2;
      if (typeof this.fieldAttributes.treeMin != 'undefined') {
        treeMin = this.fieldAttributes.treeMin;
      }
      return treeMin;
    },
    checkedSource() {
      let checkedSource = this.result.valueLine.source;
      if (
          typeof checkedSource != 'undefined' &&
          checkedSource == 't_responseTemplates'
      ) {
        checkedSource == 't_templates';
      }
      return checkedSource;
    },
    checkInputSource() {
      let checkedSource = this.fieldAttributes.source;
      if (
          typeof checkedSource != 'undefined' &&
          checkedSource == 't_responseTemplates'
      ) {
        checkedSource == 't_templates';
      }
      return checkedSource;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    tag() {
      let valueArray = arrayFindInArray(
          this.result.valueLine.attribute,
          this.result.fields.attribute.associatedOption,
      );
      let tag = valueArray[1];
      return tag;
    },
    treeLength() {
      let treeLength = 0;
      if (
          typeof this.result.fields.tree != 'undefined' &&
          typeof this.result.valueLine.tree != 'undefined'
      ) {
        let tree = this.result.valueLine.tree;
        let treeArray = tree.split('.');
        treeLength = treeArray.length;
      }
      return treeLength;
    },
  },
};
</script>
<style>
.attribute-text-red input {
  color: red !important;
}

.attribute-text-green input {
  color: green !important;
}

.attribute-text-blue input {
  color: blue !important;
}
</style>